import {SSO_URL} from '../../Constants'
import cookie from "js-cookie";

const AuthRoute = ({ children,  isLoggedIn}) => {
    if(isLoggedIn || (cookie.get('is_logged_in') === '1') ){
        return children;
    }else{
        const redirectUrl = window.location.origin+"/login-by-token"; 
        window.location.href = SSO_URL+"?redirect_from="+encodeURI(redirectUrl);

        return <></>;
    }
};


export default AuthRoute;