export const API_URL = process.env.REACT_APP_API_URL
export const APP_NAME = process.env.REACT_APP_NAME;
export const API_OK = "OK";
export const API_EXCEPTION = "Exception";
export const SSO_URL = process.env.REACT_APP_SSO_URL
export const DEFAULT_ERROR_TITLE = "Error";
export const DEFAULT_ERROR_TEXT = "Error ocurred during operation";
export const ERROR_SESSION_EXPIRED = "SESSION_EXPIRED";
export const ERROR_BAD_SESSION = "BAD_SESSION";
export const ERROR_UNAUTHORIZED = "unauthorized";
export const LINE_STATUS_ACTIVE = "ACTIVE";
export const CALL_TYPE_ALL = "ALL";
export const CALL_TYPE_INCOMING = "IN";
export const CALL_TYPE_OUTCOMING = "OUT";
export const CALL_TYPE_TRANSFER = "TRANSFER";
export const BLOCK_LIST_MODE_BLACKLIST = "BLACKLIST";
export const BLOCK_LIST_MODE_WHITELIST = "WHITELIST";
export const BLOCK_LIST_MODE_INHERIT = "INHERITANCE";
export const WATCH_STATUS_ON = "ON";
export const WATCH_STATUS_BLOCKED = "BLOCKED";
export const WATCH_STATUS_OPEN = "OPEN";
export const WATCH_STATUS_CLOSED = "CLOSE";
export const WATCH_STATUS_CALLS_ALL = "ALL";
export const WATCH_STATUS_CALLS_IN = "IN";
export const WATCH_STATUS_CALLS_OUT = "OUT";
export const ALL = "ALL";
export const ACTION_ADD = "add";
export const ACTION_EDIT = "edit";
export const CALL_TYPE_IN = "IN";
export const CALL_TYPE_OUT = "OUT";
export const STATUS_BLOCKED = "BLOCKED";
export const STATUS_OPEN = "OPEN";
export const ERROR_BAD_ID = "BAD_ID";
export const ERROR_BAD_NEW_OBJ = "BAD_NEW_OBJ";
export const ERROR_NUMBERS_TO_PERFORM_REQUIRED = "NUMBERS_TO_PERFORM_REQUIRED";
export const ERROR_NO_CHANGES_WERE_MADE = "NO_CHANGES_WERE_MADE";
export const ERROR_BAD_REQUEST = "BAD_REQUEST";
export const INCORRECT_LOGIN = "INCORRECT_LOGIN";
export const LIABILITY_POPUP_APPROVED = "liability_popup_approved";
export const CLOCK_STATUS_ON = "ON";
export const CLOCK_STATUS_BLOCKED = "BLOCKED";
export const CLOCK_STATUS_OPEN = "OPEN";
export const CLOCK_STATUS_CLOSED = "CLOSE";
export const CLOCK_STATUS_CALLS_ALL = "ALL";
export const CLOCK_STATUS_CALLS_IN = "IN";
export const CLOCK_STATUS_CALLS_OUT = "OUT";
export const PASSWORD_CALL_AUTH_STATUS_ON = "ON";
export const PASSWORD_CALL_AUTH_STATUS_OFF = "OFF";
export const WATCH_SERVICE_CALLS = "CALL";
export const WATCH_SERVICE_SMS = "SMS";