import {DEFAULT_ERROR_TITLE, ERROR_SESSION_EXPIRED, ERROR_BAD_SESSION, ERROR_UNAUTHORIZED, SSO_URL} from './Constants'
import cookie from "js-cookie";

export function formatErrorMessage(errorMessage, t){
    const translatedErrorMessage = t(errorMessage);
    if (translatedErrorMessage !== errorMessage){
        errorMessage = errorMessage + " - " + translatedErrorMessage;
    }
    return errorMessage;
}

export function handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate) {
    const sessionErrors = [
        ERROR_SESSION_EXPIRED,
        ERROR_BAD_SESSION,
        ERROR_UNAUTHORIZED
    ];

    if(sessionErrors.includes(errorMessage)
        && (logout !== null)){
        if((errorMessage !== ERROR_SESSION_EXPIRED) && (typeof errorMessage !== 'undefined')){
            cookie.set('logout_error', errorMessage);
        }
        
        dispatch(logout());
        const redirectUrl = window.location.origin+"/login-by-token";
        window.location.href = SSO_URL+"?redirect_from="+encodeURI(redirectUrl);
    }else{
        if(typeof errorMessage !== 'undefined'){
            errorMessage = formatErrorMessage(errorMessage, t);
            dispatch(showErrorDialog({title : t(DEFAULT_ERROR_TITLE), text: errorMessage}));
        }
    }
}