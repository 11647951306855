import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from "../../features/user";
import axios from "axios";
import { API_URL, API_OK, API_EXCEPTION, DEFAULT_ERROR_TEXT, LINE_STATUS_ACTIVE } from '../../Constants';
import { Backdrop, CircularProgress, Typography, Select, MenuItem } from '@mui/material';
import { Stack, Button, Box, Dialog, IconButton, TextField, Container } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid, heIL } from '@mui/x-data-grid';
import '../../styles.css';
import cookie from "js-cookie";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../features/errorDialog";
import { handleApiErrorMessage } from "../../Helper";
import LineCalls from "../LineCalls";
import LineDetails from "../LineDetails";
import countryList from 'react-select-country-list';
import WatchSystem from '../WatchSystem';
import Category from "../Category";
import SignUpToPasswordApproval from "../PasswordApproval/SignUp";
import PasswordApproval from "../PasswordApproval/Service";
import SignUpToCallRecordings from "../CallRecordings/SignUp";
import CallRecordings from "../CallRecordings/Service";
import SignUpToAntiSpam from "../AntiSpam/SignUp";
import AntiSpamService from "../AntiSpam/Service";
import AntiSpamServiceDescription from "../AntiSpam/Description";
import FileSaver from 'file-saver';
import * as uuid from 'uuid';
const uuidv4 = uuid.v4;


export default function LinesManagement() {
  const { t } = useTranslation();
  const [callsReportDialogIsOpen, setCallsReportDialogIsOpen] = useState(false);
  const [watchProfileDialogIsOpen, setWatchProfileDialogIsOpen] = useState(false);
  const [antiSpamInfoDialogIsOpen, setAntiSpamInfoDialogIsOpen] = useState(false);
  const [lineDetailsDialogIsOpen, setLineDetailsDialogIsOpen] = useState(false);
  const [dialogLoaderVisible, setDialogLoaderVisible] = useState(false);
  const [blocksDialogIsOpen, setBlocksDialogIsOpen] = useState(false);
  const [currentAdditionalServicesRowValues, setCurrentAdditionalServicesRowValues] = useState(null);

  const [serviceScreenDialogIsOpen, setServiceScreenDialogIsOpen] = useState({
    'password_approval': false,
    'recording': false,
    'anti_spam': false
  });

  const [serviceSignUpScreenDialogIsOpen, setServiceSignUpScreenDialogIsOpen] = useState({
    'password_approval': false,
    'recording': false,
    'anti_spam': false
  });

  const [additionalServicesMenuVisible, setAdditionalServicesMenuVisible] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const defaultSuccessMessage = t("The operation was successfull");
  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);

  const initalSelectedLineValue = {
    id: null,
    number: null,
    use_name: null
  };
  const [selectedLine, setSelectedLine] = useState(initalSelectedLineValue);

  const initialSearchInput = {
    number: '',
    country_code: 'ALL',
    use_name: '',
    group_id: ''
  };
  const [searchInput, setSearchInput] = useState(initialSearchInput);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const DEAFULT_PAGE_SIZE = 50;
  const [pageSize, setPageSize] = useState(DEAFULT_PAGE_SIZE);
  const [page, setPage] = useState(0);
  const countryOptions = useMemo(() => countryList().getValues(), [])

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    { field: 'id', headerName: t('Line ID'), width: 330, flex: 1 },
    { field: 'number', headerName: t('Number'), width: 330, flex: 1 },
    {
      field: 'status', headerName: t('Status'), width: 130,
      valueGetter: (params) => {
        switch (params.row.status) {
          case LINE_STATUS_ACTIVE:
            return t("Active");
          default:
            return params.row.status;
        }
      },
      flex: 1, sortable: false
    },
    { field: 'create_date', headerName: t('Created at'), width: 330, flex: 1, filterable: false, sortable: false },
    { field: 'use_name', headerName: t('User name'), width: 330, flex: 1 },
    { field: 'group_id', headerName: t('Group ID'), width: 330, flex: 1 },
    { field: 'default_callerid', type: 'boolean', headerName: t('Default Caller ID'), width: 330, flex: 1 },
    { field: 'last_call', headerName: t('Last call date'), width: 330, flex: 1 },
    {
      field: 'view_details', headerName: '', width: 330, flex: 1, sortable: false, filterable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleViewDetails(e, cellValues)}
          >
            {t('Line Details')}
          </Button>
        );
      }
    },
    {
      field: 'view_calls_report', headerName: '', width: 330, flex: 1, sortable: false, filterable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleViewCallsReport(e, cellValues)}
          >
            {t('Display Calls Report')}
          </Button>
        );
      }
    },
    {
      field: 'view_additional_services', headerName: '', flex: 1.5, sortable: false, filterable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleViewAdditionalServicesRow(e, cellValues)}
          >
            <div>{t('Additional Services')}</div>
            <div className='arrow white arrow-down'>
            </div>
          </Button>
        );
      }
    }
  ];


  const handleViewBlocksRow = (cellValues) => {
    setAdditionalServicesMenuVisible(false);
    setSelectedLine({
      id: cellValues.row.id,
      use_name: cellValues.row.use_name,
      number: cellValues.row.number
    });
    setBlocksDialogIsOpen(true);
  };

  const handleViewAdditionalServicesRow = (e, cellValues) => {
    setCurrentAdditionalServicesRowValues(cellValues);

    if (!additionalServicesMenuVisible) {
      setAdditionalServicesMenuVisible(true);
      let grid = document.querySelector(".MuiDataGrid-root");
      if (grid) {
        let menu = document.getElementById("additional-services-menu");
        menu.style.left = (e.target.closest("button").offsetLeft + grid.offsetLeft - 19) + "px";
        menu.style.top = (e.target.closest("button").offsetTop + grid.offsetTop + 90) + "px";
      }

    } else {
      setAdditionalServicesMenuVisible(false);
    }
  };

  useEffect(() => {
    if (loading) {
      return () => { };
    }
    refreshLinesData();
  }, [page, pageSize]);


  const resetCallsReportDialogHideLoader = () => {
    setCallsReportDialogIsOpen(false);
    setSelectedLine(initalSelectedLineValue);
  };
  const resetLineDetailsDialogHideLoader = () => {
    setLineDetailsDialogIsOpen(false);
    setSelectedLine(initalSelectedLineValue);
  };
  const resetWatchProfileDialogHideLoader = () => {
    setWatchProfileDialogIsOpen(false);
    setSelectedLine(initalSelectedLineValue);
  };

  const refreshLinesData = (isDownload = false) => {
    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let headers = {
      token: cookie.get('token')
    }

    setDialogLoaderVisible(true);

    const apiUrl = API_URL + '/lines/search';

    let params = {
      start: page * pageSize,
      limit: pageSize
    };

    for (const key of Object.keys(searchInput)) {
      if (key === 'country_code' && searchInput[key] === 'ALL') {
        continue;
      }
      if (searchInput[key] !== '') {
        params[key] = searchInput[key];
      }
    }

    if (isDownload) {
      params['start'] = 0;
      params['limit'] = 999999;
      params['export'] = true;
      fetch(apiUrl, {
        credentials: 'same-origin',
        method: 'post',
        headers: { 'Content-Type': 'application/json', ...headers },
        body: JSON.stringify(params)
      }).then(function (response) {
        setLoading(false);
        if (response['headers'].get('content-type') === 'text/csv; charset=utf-8') return response.blob().then(function (blob) {
          FileSaver.saveAs(blob, `kehilot-reports-${uuidv4()}.csv`);
        });
        return response.json().then(function (data) {
          if (data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("callList") && data.hasOwnProperty("totalCount"))) {
            setRows(data.callList);
            setLoading(false);
          } else {
            if ((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))) {
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setLoading(false);
          }
        });
      }).catch((e) => {
        handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        setLoading(false);
      })
    } else {
      axios.post(apiUrl,
        params,
        { headers })
        .then(
          ({ data }) => {
            if (data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("lines"))) {
              setRows(data.lines);
              setLoading(false);
              setDialogLoaderVisible(false);
            } else {
              if ((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))) {
                errorMessage = data["message"];
              }
              handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
              setLoading(false);
              setDialogLoaderVisible(false);
            }
          }).catch((error) => {
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setLoading(false);
            setDialogLoaderVisible(false);
          });
    }
  };


  const handleViewDetails = (e, cellValues) => {
    setSelectedLine({
      id: cellValues.row.id,
      number: cellValues.row.number,
      use_name: cellValues.row.use_name
    });
    setLineDetailsDialogIsOpen(true);
  };

  const handleViewCallsReport = (e, cellValues) => {
    setSelectedLine({
      id: cellValues.row.id,
      number: cellValues.row.number,
      use_name: cellValues.row.use_name
    });
    setCallsReportDialogIsOpen(true);
  };

  const handleViewWatchProfile = (cellValues) => {
    setAdditionalServicesMenuVisible(false);
    setSelectedLine({
      id: cellValues.row.id,
      number: cellValues.row.number,
      use_name: cellValues.row.use_name
    });
    setWatchProfileDialogIsOpen(true);
  };

  const handleViewAntiSpamServiceScreen = async (cellValues) => {
    setAdditionalServicesMenuVisible(false);

    let apiUrl = API_URL + '/antiSpam/' + cellValues.row.id + '/registration';
    let isSignedUpToService = await getServiceRegistrationStatus(cellValues, apiUrl);

    setSelectedLine({
      id: cellValues.row.id,
      number: cellValues.row.number,
      use_name: cellValues.row.use_name
    });

    if (!isSignedUpToService) {
      setServiceSignUpScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'anti_spam': true });
    } else {
      setServiceScreenDialogIsOpen({ ...serviceSignUpScreenDialogIsOpen, 'anti_spam': true });
    }
  };

  const handleViewPasswordApprovalScreen = async (cellValues) => {
    setAdditionalServicesMenuVisible(false);

    let apiUrl = API_URL + '/incomingCallsPassword/' + cellValues.row.id + '/registration';
    let isSignedUpToService = await getServiceRegistrationStatus(cellValues, apiUrl);

    setSelectedLine({
      id: cellValues.row.id,
      number: cellValues.row.number,
      use_name: cellValues.row.use_name
    });

    if (!isSignedUpToService) {
      setServiceSignUpScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'password_approval': true });
    } else {
      setServiceScreenDialogIsOpen({ ...serviceSignUpScreenDialogIsOpen, 'password_approval': true });
    }

    setAdditionalServicesMenuVisible(false);
  };

  const handleViewRecordingServiceScreen = async (cellValues) => {
    setAdditionalServicesMenuVisible(false);

    let apiUrl = API_URL + '/callsRecords/' + cellValues.row.id + '/registration';
    let isSignedUpToService = await getServiceRegistrationStatus(cellValues, apiUrl);

    setSelectedLine({
      id: cellValues.row.id,
      number: cellValues.row.number,
      use_name: cellValues.row.use_name
    });

    if (!isSignedUpToService) {
      setServiceSignUpScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'recording': true });
    } else {
      setServiceScreenDialogIsOpen({ ...serviceSignUpScreenDialogIsOpen, 'recording': true });
    }

    setAdditionalServicesMenuVisible(false);
  };

  const getServiceRegistrationStatus = async (cellValues, apiUrl) => {
    let isSignedUpToService = false;

    let errorMessage = t(DEFAULT_ERROR_TEXT);
    setLoading(true);

    let headers = {
      token: cookie.get('token')
    }

    await axios.get(apiUrl, { headers })
      .then(
        ({ data }) => {
          if (data.hasOwnProperty("responseStatus") && (data["responseStatus"] === API_OK && data.hasOwnProperty("status"))) {
            isSignedUpToService = data['status'];
            setLoading(false);
          } else {
            if ((data.responseStatus === API_EXCEPTION) && (data.hasOwnProperty("message"))) {
              errorMessage = data["message"];
            }
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            setLoading(false);
          }
        }).catch((error) => {
          handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
          setLoading(false);
        });

    return isSignedUpToService;
  };

  const handleCallsReportDialogClose = () => {
    resetCallsReportDialogHideLoader();
  };

  const handleWatchProfileDialogClose = () => {
    resetWatchProfileDialogHideLoader();
  };

  const handleLineDetailsDialogClose = () => {
    resetLineDetailsDialogHideLoader();
  };

  const handleBlocksDialogClose = () => {
    setBlocksDialogIsOpen(false);
  };

  const handlePasswordApprovalScreenDialogClose = () => {
    setServiceScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'password_approval': false });
  };

  const handleSignUpToPasswordApprovalScreenDialogClose = () => {
    setServiceSignUpScreenDialogIsOpen({ ...serviceSignUpScreenDialogIsOpen, 'password_approval': false });
  };

  const changeSearchFilters = (e, fieldName) => {
    let newSearchInput = {
      number: searchInput.number,
      country_code: searchInput.country_code,
      use_name: searchInput.use_name,
      group_id: searchInput.group_id
    };

    newSearchInput[fieldName] = e.target.value;

    setSearchInput(newSearchInput);
  };

  const handleSearchSubmit = (e) => {
    refreshLinesData();
  };

  const exportSearchToFile = (e) => {
    refreshLinesData(true);
  };

  return (
    <Container maxWidth="xl" className="content-wrapper">
      <>
        <Box className="success-message" sx={{ flexGrow: 1, display: (showSuccessMessage ? "flex" : "none"), justifyContent: 'space-between', alignItems: "center" }}>
          <div>{successMessage}</div>
          <IconButton onClick={() => setShowSuccessMessage(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ border: 1, pr: 2, pl: 2, mb: 2, borderColor: 'grey.300', borderRadius: '1%' }}>
          <Box>
            <Typography variant="h6">{t("Search box")}</Typography>
          </Box>
          <Stack spacing={1}
            direction="row"
            flexWrap="wrap"
            sx={[
              {
                mb: 2,
                flexWrap: "wrap"
              }
            ]}
          >
            <Box sx={{ ml: 2 }}>
              <Typography>{t('Number')}</Typography>
              <TextField size="small" id="search-number" value={searchInput.number} onChange={(e) => changeSearchFilters(e, "number")} />
            </Box>
            <Box sx={{ ml: 2 }}>
              <Typography>{t('Country Code')}</Typography>
              <Select
                size="small"
                value={searchInput.country_code}
                onChange={(e) => changeSearchFilters(e, "country_code")}
                id="search-country-code"
                sx={{
                  width: {
                    lg: "210px"
                  }
                }}
              >
                <MenuItem key='ALL' value='ALL'>
                  {t('All')}
                </MenuItem>
                {countryOptions.map((code) => (
                  <MenuItem key={code} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ ml: 2 }}>
              <Typography>{t('User Name')}</Typography>
              <TextField size="small" id="search-use-name" value={searchInput.use_name} onChange={(e) => changeSearchFilters(e, "use_name")} />
            </Box>
            <Box sx={{ ml: 2 }}>
              <Typography>{t('Group ID')}</Typography>
              <TextField size="small" id="search-group-id" value={searchInput.group_id} onChange={(e) => changeSearchFilters(e, "group_id")} />
            </Box>
            <Box direction="row">
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => handleSearchSubmit(e)}
                sx={{ width: 210, mt: 3 }}
              >
                {t('Search')}
              </Button>
            </Box>
            <Box direction="row">
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => exportSearchToFile(e)}
                sx={{ width: 210, mt: 3 }}
              >
                {t('Export To File')}
              </Button>
            </Box>
          </Stack>
        </Box>
        <DataGrid
          className='lines-management'
          disableSelectionOnClick={true}
          rows={rows}
          rowCount={999999}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          localeText={heIL.components.MuiDataGrid.defaultProps.localeText}
          loading={loading}
        />
        <div className={'additional-services-menu ' + (additionalServicesMenuVisible ? '' : 'hidden')}
          id='additional-services-menu'>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleViewBlocksRow(currentAdditionalServicesRowValues)}
          >
            {t('Block List Management')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleViewWatchProfile(currentAdditionalServicesRowValues)}
          >
            {t('Watch Configurations')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleViewPasswordApprovalScreen(currentAdditionalServicesRowValues)}
          >
            {t('Password callers approval')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleViewRecordingServiceScreen(currentAdditionalServicesRowValues)}
          >
            {t('Call recording service')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleViewAntiSpamServiceScreen(currentAdditionalServicesRowValues)}
          >
            {t('Anti spam service')}
          </Button>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={dialogLoaderVisible}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
        <Dialog open={lineDetailsDialogIsOpen} onClose={handleLineDetailsDialogClose}
          fullWidth
          maxWidth={'2000px'}
        >
          <DialogTitle>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'flex-end' }}>
              <IconButton onClick={handleLineDetailsDialogClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent style={{ height: '80vh' }}>
            <LineDetails line={selectedLine} />
          </DialogContent>
        </Dialog>
        <Dialog open={callsReportDialogIsOpen} onClose={handleCallsReportDialogClose}
          fullWidth
          maxWidth={'2000px'}
        >
          <DialogTitle>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between' }}>
              <div>{t("Line Calls Report")}</div>
              <IconButton onClick={handleCallsReportDialogClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent style={{ height: '80vh' }}>
            <LineCalls line={selectedLine} />
          </DialogContent>
        </Dialog>
        <Dialog open={watchProfileDialogIsOpen} onClose={handleWatchProfileDialogClose}
          fullWidth
          maxWidth={'2000px'}
        >
          <DialogTitle>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between' }}>
              <div>
                {t("Watch Configurations for line with ID {{line_id}}", { line_id: selectedLine.id })}
              </div>
              <IconButton onClick={handleWatchProfileDialogClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent style={{ height: '80vh' }}>
            <WatchSystem entityType="line" lineId={selectedLine.id} />
          </DialogContent>
        </Dialog>
        <Dialog open={blocksDialogIsOpen} onClose={handleBlocksDialogClose}
          fullWidth
          maxWidth={'2000px'}
        >
          <DialogTitle>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between' }}>
              <div>{t("Block List Management for line {{line}}", { line: selectedLine.number })}</div>
              <IconButton onClick={handleBlocksDialogClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent >
            <Category lineId={selectedLine.id} />
          </DialogContent>
        </Dialog>
        <Dialog open={serviceScreenDialogIsOpen['password_approval']} onClose={handlePasswordApprovalScreenDialogClose}
          fullWidth
          maxWidth={'2000px'}
        >
          <DialogTitle>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between' }}>
              <div>{t("Password fitlering service details for line {{line}}", { line: selectedLine.number })}</div>
              <IconButton onClick={handlePasswordApprovalScreenDialogClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent >
            <PasswordApproval
              lineId={selectedLine.id}
              lineNumber={selectedLine.number}
              closeDialog={handlePasswordApprovalScreenDialogClose}
              showSuccessMessageInLinesScreen={setShowSuccessMessage}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={serviceSignUpScreenDialogIsOpen['password_approval']} onClose={handleSignUpToPasswordApprovalScreenDialogClose}
          fullWidth
          maxWidth={'2000px'}
        >
          <DialogTitle>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between' }}>
              <div>{t("Registration to the password service for line {{line}}", { line: selectedLine.number })}</div>
              <IconButton onClick={handleSignUpToPasswordApprovalScreenDialogClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent >
            <SignUpToPasswordApproval
              lineId={selectedLine.id}
              closeDialog={handleSignUpToPasswordApprovalScreenDialogClose}
              displayPasswordApprovalScreen={() => {
                setServiceScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'password_approval': true });
              }}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={
          serviceScreenDialogIsOpen['recording']
        } onClose={() => {
          setServiceScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'recording': false });
        }}
          fullWidth
          maxWidth={'2000px'}
        >
          <DialogTitle>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between' }}>
              <div>{t("Calls recording service details for line {{line}}", { line: selectedLine.number })}</div>
              <IconButton onClick={() => {
                setServiceScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'recording': false });
              }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent >
            <CallRecordings
              lineId={selectedLine.id}
              lineNumber={selectedLine.number}
              closeDialog={() => {
                setServiceScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'recording': false });
              }}
              showSuccessMessageInLinesScreen={setShowSuccessMessage}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={
          serviceSignUpScreenDialogIsOpen['recording']
        } onClose={() => {
          setServiceSignUpScreenDialogIsOpen({ ...serviceSignUpScreenDialogIsOpen, 'recording': false });
        }}
          fullWidth
          maxWidth={'2000px'}
        >
          <DialogTitle>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between' }}>
              <div>{t("Registration to the recording service for line {{line}}", { line: selectedLine.number })}</div>
              <IconButton onClick={() => {
                setServiceSignUpScreenDialogIsOpen({ ...serviceSignUpScreenDialogIsOpen, 'recording': false });
              }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent >
            <SignUpToCallRecordings
              lineId={selectedLine.id}
              closeDialog={() => {
                setServiceSignUpScreenDialogIsOpen({ ...serviceSignUpScreenDialogIsOpen, 'recording': false });
              }}
              displayRecordingScreen={() => {
                setServiceScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'recording': true });
              }}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={antiSpamInfoDialogIsOpen} onClose={() => setAntiSpamInfoDialogIsOpen(false)}
          fullWidth
          maxWidth={'2000px'}
        >
          <DialogTitle>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between' }}>
              <div>{t("Information about the anti-spam service")}</div>
              <IconButton onClick={() => setAntiSpamInfoDialogIsOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent >
            <AntiSpamServiceDescription />
          </DialogContent>
        </Dialog>
        <Dialog open={
          serviceScreenDialogIsOpen['anti_spam']
        } onClose={() => {
          setServiceScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'anti_spam': false });
        }}
          fullWidth
          maxWidth={'2000px'}
        >
          <DialogTitle>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between' }}>
              <div>
                <div>{t("Anti spam service details for line {{line}}", { line: selectedLine.number })}</div>
                <div className='more-info'
                  onClick={() => setAntiSpamInfoDialogIsOpen(true)}
                >{t('Info about the service')}</div>
              </div>
              <IconButton onClick={() => {
                setServiceScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'anti_spam': false });
              }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <AntiSpamService
              lineId={selectedLine.id}
              lineNumber={selectedLine.number}
              closeDialog={() => {
                setServiceScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'anti_spam': false });
              }}
              showSuccessMessageInLinesScreen={setShowSuccessMessage}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={
          serviceSignUpScreenDialogIsOpen['anti_spam']
        } onClose={() => {
          setServiceSignUpScreenDialogIsOpen({ ...serviceSignUpScreenDialogIsOpen, 'anti_spam': false });
        }}
          fullWidth
          maxWidth={'2000px'}
        >
          <DialogTitle>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: 'space-between' }}>
              <div>{t("Registration to the anti spam for line {{line}}", { line: selectedLine.number })}</div>
              <IconButton onClick={() => {
                setServiceSignUpScreenDialogIsOpen({ ...serviceSignUpScreenDialogIsOpen, 'anti_spam': false });
              }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent >
            <SignUpToAntiSpam
              lineId={selectedLine.id}
              closeDialog={() => {
                setServiceSignUpScreenDialogIsOpen({ ...serviceSignUpScreenDialogIsOpen, 'anti_spam': false });
              }}
              displayAntiSpamScreen={() => {
                setServiceScreenDialogIsOpen({ ...serviceScreenDialogIsOpen, 'anti_spam': true });
              }}
            />
          </DialogContent>
        </Dialog>
      </>
    </Container>
  );
}
